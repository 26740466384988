/*

  Scripts

*/

require('./1_settings/settings');
require('./2_vendors/vendors');
require('./3_objects/objects');
require('./4_components/components');

require('../scss/styles.scss');