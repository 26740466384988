/*

  Image

*/

global.image = {
    lazy: new LazyLoad({
        elements_selector: '.o-image--lazy'
    }),
};

