/*

  To Top

*/

var totop = {
    wrapper: $('#totop'),
    init: function () {
        totop.wrapper.on('click', function () {
            $('body,html').animate({
                scrollTop: 0
            }, 1);
        })
    }
};


if (totop.wrapper.length) {
    totop.init();
}
