/*

  Social Feed

*/



masonry = {
    wrapper: $('.grid'),
    update: function () {
        masonry.wrapper.masonry('reloadItems');
        masonry.wrapper.masonry('layout');
    }
};

if (masonry.wrapper.length) {

    masonry.wrapper.masonry({
        itemSelector: '.grid-item'
    });

    masonry.lazy = new LazyLoad({
        elements_selector: '.masonry-lazy',
        callback_loaded: function () {
            masonry.update();
        },
    });
}
