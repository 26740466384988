/*

  Objects

*/

/*

  Slider

*/

import {tns} from 'tiny-slider/src/tiny-slider';

var slider = {
    wrapper: $('.o-slider'),
    defaults: {
        container: '.o-slider',
        lazyload: true,
        lazyloadSelector: '.tns-lazy-img',
        items: 1,
        slideBy: 'page',
        autoplay: true,
        autoplayButtonOutput: false,
        controls: true,
        controlsText: ['<i class="icon icon--prev"></i>', '<i class="icon icon--next"></i>'],
        navPosition: 'bottom',
        mouseDrag: true
    },
    init: function () {
        slider.wrapper.each(function () {
            slider.this = $(this);
            tns(slider.defaults);
        });
    }
};

if (slider.wrapper.length) {
    slider.init();
}
