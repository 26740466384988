/*

  Navigation

*/

$('.c-navigation__toggler').on('click', function () {
    $(this).toggleClass('active')
    $('.c-navigation__innerwrap').slideToggle('fast');
});
